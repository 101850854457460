<template>
  <b-form @submit.prevent="save">
    <b-modal id="modalTransfers" :title="$t('transferDate')" @shown="init">
      <div class="form-group">
        <label for="start">{{ $t('dateNextSigning') }}</label>
        <date-picker v-model="match.start" :config="options" name="start" autocomplete="off" v-validate="'required'" :state="validateState('start')"></date-picker>
        <span class="error-inputs">{{ errors.first('start') }}</span>
      </div>
      <div class="form-group">
        <label for="end">{{ $t('endDate') }}</label>
        <date-picker v-model="match.end" :config="options" name="end" autocomplete="off" v-validate="'required'" :state="validateState('end')"></date-picker>
        <span class="error-inputs">{{ errors.first('end') }}</span>
      </div>
      <template slot="modal-footer">
        <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalTransfers')">{{ $t('cancel') }}</b-button>
        <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    division: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      match: {},
      options: {
        format: 'DD-MM-YYYY HH:mm:ss',
        useCurrent: false,
      }
    }
  },
  computed: {
    ...mapGetters(['lang', 'console'])
  },
  methods: {
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const tournamentId = this.$route.params.tournamentId
          const divisionId = this.$route.params.divisionId
          const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/division/${divisionId}/transfers/store`
          this.$axios.post(path, this.match).then(() => {
            this.$root.$emit('bv::hide::modal', 'modalTransfers')
            this.$emit('updateTransfers', this.match)
            this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    init () {
      this.match = {
        start: this.division.start_transfer,
        end: this.division.end_transfer
      }
    }
  }
}
</script>
