<template>
  <b-modal id="modalCreateFixture" :title="$t('createFixture')">
    <h6>{{ $t('msgCreateFixture') }}</h6>
    <template slot="modal-footer">
      <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalCreateFixture')">{{ $t('cancel') }}</b-button>
      <b-button type="button" @click="createFixture" variant="primary">{{ $t('btnIfContinue') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      tournamentId: this.$route.params.tournamentId,
      divisionId: this.$route.params.divisionId
    }
  },
  computed: {
    ...mapGetters(['lang', 'console', 'division'])
  },
  methods: {
    createFixture () {
      const path = `auth/${this.lang}/${this.console}/tournaments/${this.tournamentId}/division/${this.divisionId}/create-fixture`
      this.$axios.post(path).then(response => {
        this.$toastr.success(response.data.message)
        this.$root.$emit('bv::hide::modal', 'modalCreateFixture')
      })
    }
  }
}
</script>
