<template>
  <b-form @submit.prevent="save">
    <b-modal id="modalChangeDate" :title="$t('changeDatesRounds')" @shown="init">
      <div class="form-group">
        <label for="round">{{ $tc('round', 1) | toCapitalize }}</label>
        <select name="round" id="round" class="form-control" v-validate="'required'" :state="validateState('statroundurounds')" v-model="datetime.round" @change="showMyDate">
          <option v-for="round in rounds" :key="`round-${round.round}`" :value="round.round">{{ round.round }}</option>
        </select>
        <span class="error-inputs">{{ errors.first('round') }}</span>
      </div>
      <div class="form-group">
        <label for="teamDowns">{{ $t('date') }} - {{ $t('time') }}</label>
        <date-picker v-model="datetime.datetime" :config="options" name="datetime" autocomplete="off" v-validate="'required'" :state="validateState('teamDowns')"></date-picker>
        <span class="error-inputs">{{ errors.first('teamDowns') }}</span>
      </div>
      <template slot="modal-footer">
        <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalChangeDate')">{{ $t('cancel') }}</b-button>
        <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    division: {
      required: true,
      type: Object
    },
    rounds: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      datetime: {},
      options: {
        format: 'DD-MM-YYYY H:mm:ss',
        useCurrent: false,
      }
    }
  },
  computed: {
    ...mapGetters(['lang', 'console'])
  },
  methods: {
    showMyDate () {
      this.rounds.map(round => {
        if (round.round === this.datetime.round) {
          this.datetime.datetime = round.date_game
        }
      })
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const payload = {
            season: this.division.season,
            round: this.datetime.round,
            date: this.datetime.datetime
          }
          const tournamentId = this.$route.params.tournamentId
          const divisionId = this.$route.params.divisionId
          const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/division/${divisionId}/date-game/store`
          this.$axios.post(path, payload).then(() => {
            this.datetime.datetime = ''
            this.$emit('updateRounds', payload)
            this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    init () {
      this.datetime = {}
    }
  }
}
</script>
