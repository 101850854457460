<template>
  <div class="content">
    <div class="title-actions">
      <h5 class="title">{{ division.name }}</h5>
      <div class="btns">
        <b-form-input v-model="filter" :placeholder="$t('search')" autofocus />
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
        <b-dropdown id="ddDivisionOptions" right noCaret>
            <div slot="button-content" class="px-2">
              <i class="fa fa-ellipsis-v"></i>
            </div>
            <b-dropdown-item v-if="showAddTeams" @click="openModalAddTeam">{{ $t('addTeam') }}</b-dropdown-item>
            <b-dropdown-item v-if="showAddTeams" @click="openModalCreateFixture">{{ $t('createFixture') }}</b-dropdown-item>
            <b-dropdown-item v-if="division.state === 'EN CURSO'" :to="{ name: 'DivisionBackLiguilla', params: { lang: lang, console: console, tournamentId: tournamentId, divisionId: divisionId } }" >{{ $t('createLiguilla') }}</b-dropdown-item>
            <b-dropdown-item @click="showFixture">{{ $t('showFixture') }}</b-dropdown-item>
            <b-dropdown-item @click="$root.$emit('bv::show::modal', 'modalChangeDate')">{{ $t('changeDatesRounds') }}</b-dropdown-item>
            <b-dropdown-item @click="$root.$emit('bv::show::modal', 'modalDateGame')">{{ $t('schedule') }}</b-dropdown-item>
            <b-dropdown-item @click="toCustomButons">{{ $t('customButtons') }}</b-dropdown-item>
            <b-dropdown-item @click="$root.$emit('bv::show::modal', 'modalTransfers')">{{ $t('transferDate') }}</b-dropdown-item>
            <b-dropdown-item @click="toTeamWeek">{{ $t('teamWeek') }}</b-dropdown-item>
            <b-dropdown-item @click="toNotices">{{ $t('notices') }}</b-dropdown-item>
          </b-dropdown>
      </div>
    </div>
    <hr>
    <b-table striped bordered :items="teams" :fields="columns" :filter="filter">
      <div slot="order" class="text-right" slot-scope="row">{{ row.index + 1 }}</div>
      <div slot="name" slot-scope="row">
        <router-link :to="{ name: 'TeamBack', params: { lang: lang, console: console, teamId: row.item.id } }">{{ row.value }}</router-link>
      </div>
      <div slot="dts" slot-scope="row">
        <router-link v-if="row.item.dt" :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: row.item.dt.slug } }">DT {{ row.item.dt.nick }}</router-link>
        <router-link v-else-if="row.item.codt" :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: row.item.codt.slug } }">CODT {{ row.item.codt.nick }}</router-link>
      </div>
      <div v-if="type === 'SUPER' || type === 'ADMIN' || type === 'FULL'" slot="actions" slot-scope="row" class="text-center">
        <b-button variant="danger" @click="openModalDestroy(row.item)">
          <i class="fa fa-trash"></i>
        </b-button>
      </div>
    </b-table>
    <b-modal ref="modalDelete" :title="$t('confirmTitleDelete')" headerBgVariant="danger" headerTextVariant="light">
      <p>{{$t('confirmTextDelete')}}</p>
      <strong>{{ team.name }}</strong>
      <div slot="modal-footer">
        <b-button @click="$refs.modalDelete.hide()">{{$t('cancel')}}</b-button>
        <b-button variant="danger" @click="destroy" class="ml-3">{{$t('delete')}}</b-button>
      </div>
    </b-modal>
    <modal-show-date :division="division" :rounds="rounds" @updateRounds="updateRounds" />
    <modal-date-game :division="division" @updateSchedule="updateSchedule" />
    <modal-transfers :division="division" @updateTransfers="updateTransfers" />
    <modal-fixture :fixture="fixture" />
    <modal-add-team />
    <modal-create-fixture />
  </div>
</template>
<script>
import ModalShowDate from './ModalShowDate'
import ModalDateGame from './ModalDateGame'
import ModalTransfers from './ModalTransfers'
import ModalFixture from './ModalFixture'
import ModalAddTeam from './ModalAddTeam'
import ModalCreateFixture from './ModalCreateFixture'
import { mapGetters } from 'vuex'

export default {
  components: {
    ModalShowDate,
    ModalDateGame,
    ModalTransfers,
    ModalFixture,
    ModalAddTeam,
    ModalCreateFixture
  },
  data () {
    return {
      filter: '',
      teams: [],
      columns: [
        { key: 'order', label: '#', sortable: false },
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'dts', label: 'DT/CODT', sortable: true },
        { key: 'actions', label: '', sortable: false }
      ],
      type: null,
      team: {},
      division: {},
      rounds: [],
      fixture: {},
      showAddTeams: false,
      tournamentId: this.$route.params.tournamentId,
      divisionId: this.$route.params.divisionId
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'tournament',
      'user'
    ])
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    openModalCreateFixture () {
      this.$root.$emit('bv::show::modal', 'modalCreateFixture')
    },
    openModalAddTeam () {
      this.$root.$emit('bv::show::modal', 'modalAddTeam')
    },
    showFixture () {
      const tournamentId = this.$route.params.tournamentId
      const divisionId = this.$route.params.divisionId
      const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/division/${divisionId}/fixture/show`
      this.$axios.get(path).then(response => {
        this.fixture = response.data
        this.$root.$emit('bv::show::modal', 'modalShowFixture')
      })
    },
    toNotices () {
      this.$store.dispatch('SET_DIVISION', this.division)
      const tournamentId = this.$route.params.tournamentId
      const divisionId = this.$route.params.divisionId
      this.$router.push({ name: 'DivisionBackNotices', params: { lang: this.lang, console: this.console, tournamentId, divisionId } })
    },
    updateTransfers (payload) {
      this.division.start_transfer = payload.start
      this.division.end_transfer = payload.end
    },
    toCustomButons () {
      this.$store.dispatch('SET_DIVISION', this.division)
      const tournamentId = this.$route.params.tournamentId
      const divisionId = this.$route.params.divisionId
      this.$router.push({ name: 'DivisionBackCustomButtons', params: { lang: this.lang, console: this.console, tournamentId, divisionId } })
    },
    toTeamWeek () {
      this.$store.dispatch('SET_DIVISION', this.division)
      const tournamentId = this.$route.params.tournamentId
      const divisionId = this.$route.params.divisionId
      this.$router.push({ name: 'DivisionBackTeamWeek', params: { lang: this.lang, console: this.console, tournamentId, divisionId } })
    },
    updateSchedule (payload) {
      this.division.game_date = payload.days.join(',')
      this.division.game_time = payload.time
    },
    updateRounds (payload) {
      this.rounds = this.rounds.map(round => {
        if (payload.round === round.round) {
          round.date_game = payload.date
        }
        return round
      })
    },
    openModalDestroy (team) {
      this.team = team
      this.$refs.modalDelete.show()
    },
    destroy () {
      const tournamentId = this.$route.params.tournamentId
      const divisionId = this.$route.params.divisionId
      const path = `auth/${this.lang}/${this.console}/tournaments/${tournamentId}/divisions/${divisionId}/delete-team/${this.team.id}`
      this.$axios.delete(path).then(() => {
        this.teams = this.teams.filter(team => {
          return team.id !== this.team.id
        })
        this.$refs.modalDelete.hide()
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
      })
    },
    fetchData () {
      const tournamentId = this.$route.params.tournamentId
      const divisionId = this.$route.params.divisionId
      const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/division/${divisionId}`
      this.$axios.get(path).then((response) => {
        const data = response.data
        this.division = data.division
        this.$store.dispatch('SET_DIVISION', this.division)
        this.teams = data.teams
        this.type = data.type
        this.rounds = data.rounds
        if (this.division.competition_type !== 'ELIMINACION DIRECTA') {
          if (this.division.state === 'POR INICIAR' || this.division.state === 'EN PROGRAMACION') {
            this.showAddTeams = true
          }
        }
      })
    }
  }
}
</script>
