<template>
  <b-modal id="modalShowFixture" title="Fixture">
    <div role="tablist">
      <b-card no-body class="mb-1" v-for="(round, i) in fixture.rounds" :key="i">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block @click="openCollapse(`accordion-${i}`)" variant="info">{{ $tc('round', 1) | toCapitalize }} {{ round }}</b-button>
        </b-card-header>
        <b-collapse  :id="`accordion-${i}`" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div class="table-responsive">
              <table class="table table-striped table-hover table-bordered">
                <tbody>
                  <tr v-for="(match, j) in fixture.fixture" :key="`tbody${j}`">
                    <td v-if="match.round === round">{{ match.local_name }}</td>
                    <td v-if="match.round === round">vs</td>
                    <td v-if="match.round === round">{{ match.visit_name }}</td>
                    <td v-if="match.round === round" class="text-center">
                      <b-button @click="exchangePosition(match)" variant="primary" title="Cambiar ubicación">
                        <i class="fa fa-exchange"></i>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <template slot="modal-footer">
      <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalShowFixture')">{{ $t('cancel') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    fixture: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      tournamentId: this.$route.params.tournamentId,
      divisionId: this.$route.params.divisionId
    }
  },
  computed: {
    ...mapGetters(['lang', 'console'])
  },
  methods: {
    exchangePosition (fixture) {
      const match = JSON.parse(JSON.stringify(fixture))
      const path = `auth/${this.lang}/${this.console}/tournament/${this.tournamentId}/division/${this.divisionId}/fixture/${match.id}/exchange`
      this.$axios.post(path).then(() => {
        this.fixture.fixture.map(fix => {
        if (fix.id === match.id) {
          fix.local_id = match.visit_id
          fix.visit_id = match.local_id
          fix.local_name = match.visit_name
          fix.visit_name = match.local_name
        }
      })
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
      })
    },
    openCollapse (accordion) {
      this.$root.$emit('bv::toggle::collapse', accordion)
    }
  }
}
</script>
