<template>
  <b-modal id="modalAddTeam" :title="`${division.name} - ${$t('addTeam')}`">
    <div class="form-group">
      <multiselect v-model="teamSelected" id="ajax" label="name" track-by="name" open-direction="bottom" :options="teams" :multiple="false" :searchable="true" :loading="loading" :internal-search="false" :clear-on-select="false" :close-on-select="true" :options-limit="10" :limit="3" :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-selected="true" @search-change="asyncFind" :placeholder="$t('selectOption')" deselectLabel="" selectLabel="" selectedLabel=""></multiselect>
    </div>
    <template slot="modal-footer">
      <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalAddTeam')">{{ $t('cancel') }}</b-button>
      <b-button :disabled="!teamSelected" type="button" @click="sendInvitation" variant="primary">{{ $t('sendInvitation') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      q: '',
      teams: [],
      teamSelected: null,
      tournamentId: this.$route.params.tournamentId,
      divisionId: this.$route.params.divisionId
    }
  },
  computed: {
    ...mapGetters(['lang', 'console', 'division', 'loading'])
  },
  methods: {
    sendInvitation () {
      if (this.teamSelected) {
        const params = {
          team_id: this.teamSelected.id
        }
        const path = `auth/${this.lang}/${this.console}/tournaments/${this.tournamentId}/division/${this.divisionId}/send-invitation-enter-tournament`
        this.$axios.post(path, params).then(response => {
          this.$toastr.success(response.data.message)
          this.teamSelected = null
          this.$root.$emit('bv::hide::modal', 'modalAddTeam')
        })
      }
    },
    limitText (count) {
      return `and ${count} other countries`
    },
    asyncFind (value) {
      if (value.length > 2) {
        this.$validator.validate().then(result => {
          if (result) {
            const params = {
              q: value
            }
            const path = `auth/${this.lang}/${this.console}/tournaments/${this.tournamentId}/find-teams`
            this.$axios.get(path, { params }).then(response => {
              const data = response.data.data
              this.teams = data.map(t => {
                t.active = false
                return t
              })
            })
          } else {
            this.showValidationsErrors(this.errors.items)
          }
        })
      }
    }
  }
}
</script>
